import React from "react"
import Header from "../components/header"
import Seo from "../components/seo"
import Collection from "../components/collection"
import Divider from "../components/divider"
import Footer from "../components/footer"

export default function Photos(props) {
  return (
    <>
      <Seo />

      <Header />
      <Divider />

      <section className="recent-work">
        <Collection title="Photos" moreText="View more photos" type="images" />
      </section>
      <Footer />
    </>
  )
}
