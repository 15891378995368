import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Collection(props) {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { sourceInstanceName: { eq: "photos" } }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            publicURL
            childImageSharp {
              fluid(maxHeight: 720) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges
  return (
    <div className="collection">
      <div className="collection__header">
        <h2>{props.title || "Collection"}</h2>
        {/* <a href="/photos" className="white-underline-link">
          {props.moreText || "View more"}
        </a> */}
      </div>

      <div className="collection__items photos">
        {props.type === "images" &&
          images.map((image, key) => {
            const img = image.node
            return (
              <Img
                fluid={img.childImageSharp.fluid}
                className="collection__item"
                key={key}
              />
            )
          })}
        {/* <button
          className="scroll-button scroll-right"
          onClick={() => {
            document.querySelector(".collection__items.photos").scrollBy({
              left: 200,
              behavior: "smooth",
            })
          }}
        >
          &rarr;
        </button>
        <button
          className="scroll-button scroll-left"
          onClick={() => {
            document.querySelector(".collection__items.photos").scrollBy({
              left: -200,
              behavior: "smooth",
            })
          }}
        >
          &larr;
        </button> */}
      </div>
    </div>
  )
}
